import {
  Box,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, ReactElement, ReactNode } from "react";
import theme from "../../theme";
import { InfoOutlined } from "@mui/icons-material";
import CustomToolTip from "./CustomToolTip";
import { formatToBillion, thousandSeparator, isBillion } from "../../utils";

function isValidNumber(str: string | number) {
  return !isNaN(+str);
}

type NumberCardProps = TextFieldProps & {
  value?: string | number | null;
  label: string;
  cardSize?: "big" | "small";
  numberSize?: "medium" | "small";
  color?: "white" | "gray";
  currency?: string;
  editMode?: boolean;
  infoTooltip?: ReactNode;
  hideCurrency?: boolean;
  hideUnit?: boolean;
  helperText?: string;
  emptyMessage?: string;
  "data-testid"?: string;
  inputTooltip?: string;
};

const NumberCard = (props: NumberCardProps): ReactElement => {
  const {
    color = "gray",
    numberSize = "medium",
    hideCurrency = false,
    hideUnit = false,
    currency = "€",
    cardSize = "small",
    value,
    editMode,
    inputTooltip,
    emptyMessage,
    ...defaultProps
  } = props;

  const isValueNumeric = (value || value === 0) && isValidNumber(value);
  const unit = isBillion(value) ? "B" : "M";
  const displayValue = isValueNumeric
    ? isBillion(value) && !hideUnit
      ? formatToBillion(value)
      : thousandSeparator(value)
    : value;

  return (
    <Fragment>
      {editMode ? (
        <TextField
          {...defaultProps}
          type="number"
          label={
            !!!inputTooltip ? (
              props.label
            ) : (
              <>
                <span>{props.label}</span>
                {editMode && (
                  <CustomToolTip
                    id={`${props.id}Tooltip`}
                    key={`${props.id}Tooltip`}
                    toolTipText={inputTooltip}
                  />
                )}
              </>
            )
          }
          InputProps={{
            startAdornment: hideCurrency ? null : (
              <InputAdornment position="start">{currency}</InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
            sx: {
              marginTop: inputTooltip ? "-5px" : "0px",
            },
          }}
          fullWidth={props.fullWidth}
          value={props.value || ""}
        />
      ) : (
        <Box
          id={props.id}
          bgcolor={
            color === "gray"
              ? theme.palette.surface.secondary.main
              : theme.palette.surface.primary.main
          }
          p={
            cardSize === "big"
              ? theme.spacing(3, 2, 3, 3)
              : theme.spacing(2, 2, 2, 3)
          }
          borderRadius={theme.shape.radius.minimal}
          width="100%"
        >
          <Typography
            variant="caption"
            color="text.mediumEmphasis"
            alignItems="center"
            display="flex"
            gap={0.5}
          >
            {cardSize === "small" && props.infoTooltip && (
              <Tooltip title={props.infoTooltip}>
                <InfoOutlined
                  sx={{
                    color: theme.palette.icon.mediumEmphasis,
                    fontSize: "1rem",
                  }}
                />
              </Tooltip>
            )}
            {props.label}
          </Typography>
          <Box
            display="flex"
            gap={cardSize === "big" ? 0.5 : 1}
            alignItems="center"
          >
            {!hideCurrency && !emptyMessage && (
              <Typography
                variant="subtitle2"
                color="text.brand.accessibility"
                pt={0.25}
              >
                {currency}
              </Typography>
            )}
            {!emptyMessage ? (
              <Typography
                variant={
                  cardSize === "big"
                    ? "h5"
                    : numberSize === "medium"
                    ? "h4"
                    : "subtitle2"
                }
                color="text.brand.accessibility"
                data-testid={
                  props["data-testid"] ? props["data-testid"] + "-value" : null
                }
              >
                {displayValue || "--"}
              </Typography>
            ) : (
              <Typography color="text.disabled" variant="body1">
                {emptyMessage}
              </Typography>
            )}
            {cardSize === "big" ? (
              <>
                {props.infoTooltip && (
                  <Tooltip
                    title={props.infoTooltip}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    <InfoOutlined
                      sx={{
                        color: theme.palette.icon.mediumEmphasis,
                        fontSize: "1rem",
                      }}
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              <>
                {!hideUnit && !!displayValue && !emptyMessage && (
                  <Typography
                    variant="subtitle2"
                    color="text.brand.accessibility"
                    p={theme.spacing(0.25, 1, 0, 0)}
                  >
                    {unit}
                  </Typography>
                )}
              </>
            )}
          </Box>
          {cardSize === "big" && props.helperText && (
            <Typography
              variant="overline"
              color="text.mediumEmphasis"
              data-testid={
                props["data-testid"]
                  ? props["data-testid"] + "-helperText"
                  : null
              }
            >
              {props.helperText}
            </Typography>
          )}
        </Box>
      )}
    </Fragment>
  );
};

export default NumberCard;
