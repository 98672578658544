import { Box, styled, TableBody, TableCell, TableRow } from "@mui/material";
import { ReactElement } from "react";
import { DetailsViewRowLabels } from "../DetailsView/DetailsView";
import theme from "../../../../../../theme";
import RequirementsViewRowLabels from "./../RequirementsView/RequirementsRowLabels";
import { StyledTable } from "../SolutionFitMatrixTable";
import { Requirement } from "../../../../../../Types/Requirement";

export const StyledVerticalHeader = styled(Box)(() => ({
  position: "sticky",
  left: 0,
  zIndex: 2,
  display: "flex",
  backgroundColor: theme.palette.common.white,
}));

export const StyledTableRow = styled(TableRow)(() => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 3,
  boxShadow: "4px 0px 8px 0px #00000014",
}));

interface SolutionFitMatrixTableProps {
  projectId: number;
  requirements: Requirement[];
  detailsView: boolean;
  isAssessStage: boolean;
  isSelectionReasonVisible: boolean;
  handleSave: () => void;
}

export default function SolutionFitMatrixVerticalHeader({
  projectId,
  requirements,
  detailsView,
  isAssessStage,
  isSelectionReasonVisible,
  handleSave,
}: SolutionFitMatrixTableProps): ReactElement {
  return (
    <StyledVerticalHeader>
      <StyledTable>
        <TableBody>
          <StyledTableRow className="head">
            <TableCell
              data-testid="startup-name"
              className="startup-name"
            ></TableCell>
            <TableCell
              data-testid="startup-link"
              className="startup-link-logo"
            ></TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Cluster</TableCell>
            <TableCell>Venture Client Readiness</TableCell>
            {isAssessStage && <TableCell>Recommend for Demo</TableCell>}
            {detailsView ? (
              <DetailsViewRowLabels />
            ) : (
              <RequirementsViewRowLabels
                requirements={requirements}
                projectId={projectId}
                handleSaveNoScroll={handleSave}
              />
            )}
            {isSelectionReasonVisible && (
              <TableCell>Selection Rationale</TableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </StyledTable>
    </StyledVerticalHeader>
  );
}
