import { Link as RouterLink } from "react-router-dom";
import {
  TableRow,
  TableCell,
  styled,
  Link,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import OpportunityRating from "../OpportunityRating/OpportunityRating";
import { SolutionCluster } from "../../../../../../Types/SolutionCluster";
import { Requirement } from "../../../../../../Types/Requirement";
import { ReactElement, useState } from "react";
import {
  Opportunity,
  OpportunityRating as OpportunityRatingType,
  RejectionReason,
} from "../../../../../../Types/Opportunity";
import AssignCluster from "../AssignCluster/AssignCluster";
import { DetailsViewStartupCells } from "../DetailsView/DetailsView";
import RequirementComments from "../RequirementsView/RequirementsComments/RequirementsComments";
import { getDateMonthsAfter, normalizeUrl } from "../../../../../../utils";
import HighlightStartup from "../HighlightStartup/HighlightStartup";
import { Flipped } from "react-flip-toolkit";
import RecommendStartup from "../RecommendStartup/RecommendStartup";
import { RequirementsStartupCells } from "../RequirementsView/RequirementsStartupCells";
import { SelectionRationale } from "../SelectionRationale/SelectionRationale";
import { FunnelStage } from "../../../../../../Types/Project";
import { Comment, RocketLaunchOutlined } from "@mui/icons-material";
import DeleteOrRemoveModal from "../../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import OpportunityHttpService from "../../../../../../Http/Opportunity/Opportunity.Http.service";
import theme from "../../../../../../theme";
import QualityVerificationIcon from "../../../../../Startup/DetailsPage/QualityVerification/QualityVerificationIcon";
import CloseIcon from "@mui/icons-material/Close";

export const StyledTableRow = styled(TableRow)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const TruncatedTableCell = styled(TableCell)(({ theme }) => ({
  "& .truncated-link": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  "& button": {
    display: "none",
    marginLeft: "auto",
    cursor: "pointer",
    color: theme.palette.common.white,
  },
  "&:hover": {
    button: {
      display: "grid",
      alignContent: "center",
    },
  },
}));

interface SolutionFitMatrixOpportunityProps {
  opportunity: Opportunity;
  opportunityLogo: string | null;
  detailsView: boolean;
  clusters: SolutionCluster[];
  requirements: Requirement[];
  expandedCommentsOpportunity: number | null;
  ventureClientId: number;
  toggleComment: () => void;
  handleRequirementCommentChange: (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => void;
  handleRatingChange: (option: OpportunityRatingType) => void;
  handleChangeCluster: (cluster: number) => void;
  handleChangeRecommended: (recommended: boolean) => void;
  handleRequirementChange: (
    updatedRequirementId: number,
    updatedStatus: string
  ) => void;
  handleInsightsUpdate: (updatedInsights: string) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  handleSave: () => void;
  isAddingStartup: boolean;
  projectFunnelStage: FunnelStage;
  currentProjectStage: FunnelStage;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  highlightedOpportunities: number[];
  isSelectionReasonVisible: boolean;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    getUpdatedReasons: string
  ) => void;
}

export default function SolutionFitMatrixOpportunity(
  props: SolutionFitMatrixOpportunityProps
): ReactElement {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const onDelete = async (id: number) => {
    await OpportunityHttpService.deleteOpportunity(id);
    props.handleSave();
  };

  const isRemovable = !props.opportunity.isSelectedForPilot;

  let isSelectionDisabled = false;
  if (!["assess", "discover"].includes(props.currentProjectStage)) {
    isSelectionDisabled = true;
  }

  const isAssessStage = props.projectFunnelStage === "assess";
  const isQualityChecked = props.opportunity.startup?.isQualityChecked;
  const expiryDate = props.opportunity.startup?.lastQualityCheckDate
    ? getDateMonthsAfter(props.opportunity.startup?.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (props.opportunity.startup?.lastQualityCheckDate &&
      isQualityChecked &&
      expiryDate < new Date()) ??
    false;

  return (
    <Flipped
      delayUntil="add-startup-button"
      key={props.opportunity.id}
      flipId={props.opportunity.id}
    >
      {(flippedProps) => (
        <>
          <StyledTableRow
            {...flippedProps}
            sx={{ cursor: props.detailsView ? "auto" : "pointer" }}
            data-testid={`details-table-startup-${props.opportunity.id}`}
            className={`opportunity-column ${
              props.detailsView ? "details" : "requirements"
            } ${
              props.expandedCommentsOpportunity === props.opportunity.id &&
              !props.detailsView
                ? "opened-comments"
                : ""
            }
        ${
          props.highlightedOpportunities.includes(props.opportunity.id)
            ? "highlighted-selection"
            : ""
        } 
        ${isAssessStage ? "selected-for-pilot" : ""}`}
            onClick={() => {
              !props.detailsView && !props.isAddingStartup
                ? props.toggleComment()
                : null;
            }}
          >
            <TruncatedTableCell
              className="startup-name"
              key={`startup-name-cell-${props.opportunity.id}`}
              data-testid="startup-name"
            >
              {props.projectFunnelStage === "discover" && (
                <HighlightStartup
                  opportunity={props.opportunity}
                  handleOpportunityStatusChange={
                    props.handleOpportunityStatusChange
                  }
                  isSelectionDisabled={isSelectionDisabled}
                />
              )}
              <RouterLink
                className="truncated-link"
                onClick={(event) => event.stopPropagation()}
                target="_blank"
                to={"/startups/" + props.opportunity.startup.id}
              >
                <Typography variant="subtitle2">
                  {props.opportunity.startup.name}
                </Typography>
              </RouterLink>

              {props.detailsView && !isAssessStage && (
                <IconButton
                  disabled={!isRemovable}
                  data-testid={`delete-opportunity-${props.opportunity.id}`}
                  onClick={() => {
                    if (!props.isAddingStartup) {
                      setDeleteModalOpen(true);
                    }
                  }}
                  sx={{
                    ":disabled": {
                      color: theme.palette.text.primaryInvert.disabled,
                    },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
              {props.expandedCommentsOpportunity !== props.opportunity.id &&
                !props.detailsView && (
                  <IconButton
                    data-testid={`delete-opportunity-${props.opportunity.id}`}
                    onClick={() => {
                      if (!props.isAddingStartup) {
                        props.toggleComment();
                      }
                    }}
                  >
                    <Comment />
                  </IconButton>
                )}
            </TruncatedTableCell>
            <TableCell
              className="startup-link-logo"
              data-testid="startup-link"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <RouterLink
                onClick={(event) => event.stopPropagation()}
                target="_blank"
                to={"/startups/" + props.opportunity.startup.id}
              >
                {props.opportunityLogo ? (
                  <img
                    src={props.opportunityLogo}
                    alt="logo associated to the startup"
                  />
                ) : (
                  <RocketLaunchOutlined color="disabled" />
                )}
              </RouterLink>
              <Box display="flex" gap={2}>
                {props.opportunity.startup.website && (
                  <Link
                    onClick={(event) => event.stopPropagation()}
                    target="_blank"
                    href={normalizeUrl(props.opportunity.startup.website)}
                  >
                    <LaunchIcon
                      data-testid="startup-link-icon"
                      htmlColor={theme.palette.icon.action.main}
                      sx={{ fontSize: "20px" }}
                    />
                  </Link>
                )}
              </Box>
            </TableCell>

            <TableCell className="rating-cell">
              <OpportunityRating
                opportunity={props.opportunity}
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                onChangeRating={(option) => {
                  props.handleRatingChange(option);
                }}
                isAddingStartup={props.isAddingStartup}
              />
            </TableCell>
            <TableCell
              className={`assign-cluster ${
                isAssessStage ? "" : "highlighted-border"
              }`}
            >
              <AssignCluster
                onChangeCluster={(cluster) => {
                  props.handleChangeCluster(cluster);
                }}
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                clusters={props.clusters}
                opportunity={props.opportunity}
                isAddingStartup={props.isAddingStartup}
              />
            </TableCell>
            <TableCell
              sx={{
                justifyContent: "center",
              }}
            >
              <QualityVerificationIcon
                lastQualityCheckBy={
                  props.opportunity.startup.lastQualityCheckBy
                }
                lastQualityCheckDate={
                  props.opportunity.startup.lastQualityCheckDate
                }
                isQualityChecked={isQualityChecked}
                hasExpired={hasExpired}
              />
            </TableCell>
            {isAssessStage && (
              <TableCell className="assign-cluster highlighted-border">
                <RecommendStartup
                  onChangeRecommended={props.handleChangeRecommended}
                  opportunity={props.opportunity}
                  isAddingStartup={props.isAddingStartup}
                />
              </TableCell>
            )}
            {props.detailsView ? (
              <DetailsViewStartupCells opportunity={props.opportunity} />
            ) : (
              <RequirementsStartupCells
                setEditingOpportunity={props.setEditingOpportunity}
                editingOpportunity={props.editingOpportunity}
                opportunity={props.opportunity}
                requirements={props.requirements}
                requirementChange={props.handleRequirementChange}
                isSelectionReasonVisible={props.isSelectionReasonVisible}
              />
            )}
            {props.isSelectionReasonVisible && (
              <SelectionRationale
                opportunity={props.opportunity}
                ventureClientId={props.ventureClientId}
                handleRejectionReasonChange={props.handleRejectionReasonChange}
              />
            )}
          </StyledTableRow>
          {!props.detailsView && (
            <RequirementComments
              isSelectionReasonVisible={props.isSelectionReasonVisible}
              requirements={props.requirements}
              expandedComments={props.expandedCommentsOpportunity}
              commentChange={props.handleRequirementCommentChange}
              opportunity={props.opportunity}
              toggleComment={() => {
                if (!props.isAddingStartup) {
                  props.toggleComment();
                }
              }}
              handleInsightsUpdate={(updatedInsights: string) =>
                props.handleInsightsUpdate(updatedInsights)
              }
              funnelStage={props.projectFunnelStage}
            />
          )}
          {deleteModalOpen && (
            <DeleteOrRemoveModal
              id={props.opportunity.id}
              modalOpen={deleteModalOpen}
              setModalOpen={setDeleteModalOpen}
              entity="startup"
              handleDelete={onDelete}
              actionType="remove"
            />
          )}
        </>
      )}
    </Flipped>
  );
}
