import { ReactElement, useContext, useState } from "react";
import {
  styled,
  Typography,
  Box,
  Chip,
  Tabs,
  Tab,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Opportunity } from "../../../../../Types/Opportunity";
import theme from "../../../../../theme";
import { CSVLink } from "react-csv";
import SolutionFitExportService from "../../../../../Services/SolutionFitExportService";
import { Project } from "../../../../../Types/Project";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";

const StyledHeader = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const ContentHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const SelectedStartupsInfo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.25),
  alignSelf: "center",
}));

const getNumberOfQualifiedStartups = (opportunities: Opportunity[]): number => {
  return opportunities.filter((opportunity) => opportunity.isQualified).length;
};

interface SolutionFitMatrixHeaderProps {
  setSelectedDetailsView: (view: boolean) => void;
  setSuggestedStartupsView?: (view: boolean) => void;
  suggestedStartupsView?: boolean;
  selectedDetailsView: boolean;
  projectFunnelStage: string;
  opportunities: Opportunity[];
  project: Project;
}

export default function SolutionFitMatrixHeader(
  props: SolutionFitMatrixHeaderProps
): ReactElement {
  const { activeStep } = useContext(GlobalProjectEditContext);

  const [exportData, setExportData] = useState<{
    headers: { label: string; key: string }[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any[];
  }>({ headers: [], body: [] });
  const isAssessStageActive = activeStep === 1;

  const downloadCSV = async () => {
    const response = SolutionFitExportService.prepareAsCSV(
      props.project,
      isAssessStageActive ? false : true
    );
    setExportData(response);
  };

  return (
    <StyledHeader data-testid="solution-fit-matrix-header">
      <Tabs value={props.selectedDetailsView ? 0 : 1}>
        <Tab
          onClick={() => props.setSelectedDetailsView(true)}
          label="Details"
        />
        <Tab
          onClick={() => props.setSelectedDetailsView(false)}
          label="Requirements"
          data-testid="list-requirements-tab"
        />
        <CSVLink
          data={exportData["body"]}
          headers={exportData["headers"]}
          className="hidden"
          filename={
            props.project.name +
            (isAssessStageActive ? "_Qualified-List.csv" : "_Curated-List.csv")
          }
          target="_blank"
          style={{ marginLeft: "auto", alignSelf: "center" }}
        >
          <Button variant="contained" onClick={downloadCSV} id="downloadCSV">
            Export to CSV
          </Button>
        </CSVLink>
      </Tabs>
      {props.projectFunnelStage === "discover" && (
        <ContentHeader>
          <SelectedStartupsInfo>
            <Typography>Startups Selected for Assess Stage</Typography>
            <Chip
              variant="choice"
              label={getNumberOfQualifiedStartups(props.opportunities)}
              sx={{ minWidth: theme.spacing(4) }}
            />
          </SelectedStartupsInfo>
          <FormControlLabel
            data-testid="suggested-startups-switch"
            control={
              <Switch
                onChange={(e) =>
                  props.setSuggestedStartupsView?.(e.target.checked)
                }
                checked={props.suggestedStartupsView}
              />
            }
            label={<Typography>Suggest Startups</Typography>}
          />
        </ContentHeader>
      )}
    </StyledHeader>
  );
}
