import { Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, InputBase, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

interface SearchBarProps {
  searchValue: string;
  handleValueChange: (value: string) => void;
  entity: string;
}
const StyledSearchBar = styled(InputBase)(() => ({
  width: "40%",
  height: 48,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.radius.full,
  backgroundColor: theme.palette.surface.primary.main,
  boxShadow: theme.boxShadows[24],
}));

export default function SearchBar({
  searchValue,
  handleValueChange,
  entity,
}: SearchBarProps): ReactElement {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    handleValueChange(newValue);
  };

  const clearSearch = () => {
    handleValueChange("");
  };

  return (
    <StyledSearchBar
      id={`search-${entity}s`}
      inputProps={{ "data-testid": `search-${entity}s` }}
      value={searchValue}
      onChange={handleChange}
      placeholder={`Search a ${entity}`}
      startAdornment={
        <InputAdornment position="start">
          <IconButton size="small" sx={{ pointerEvents: "none" }}>
            <Search color="action" />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="start">
          {searchValue && (
            <IconButton
              size="small"
              onClick={clearSearch}
              id="clear-search"
              data-testid="clear-search"
            >
              <Close />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  );
}
