import { ReactElement, useContext } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, styled, Stack, Tooltip } from "@mui/material";
import theme from "../../../theme";
import { BusinessImpactValues } from "../../../Types/Analytics";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import ImpactMetrics from "./ImpactMetrics";
import MetricsNumberCard from "./MetricsNumberCard";
import {
  numberMagnitude,
  roundNumberToMagnitude,
  thousandSeparator,
} from "../../../utils";
import { InfoOutlined } from "@mui/icons-material";
import { BUSINESS_IMPACT_TOOLTIP } from "../../../Constants/TooltipText";
import WidgetWrapper from "./WidgetWrapper";

const ContentRow = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "space-between",
}));

const ChartWrapper = styled(Box)(() => ({
  width: "100%",
  maxWidth: 168,
  height: 168,
  display: "flex",
  alignItems: "center",
}));

interface BusinessImpactProps {
  impactValuesByImpactType: BusinessImpactValues;
  median: number;
}

function BusinessImpact(props: BusinessImpactProps): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);
  const {
    Cost: cost,
    Revenue: revenue,
    "Cost & Revenue": costRevenue,
  } = props.impactValuesByImpactType;
  const sumAllValue = cost + revenue + costRevenue;

  return (
    <WidgetWrapper>
      <Box display="flex" gap={0.5} alignItems="center">
        <SkeletonWrapper isLoading={globalLoader}>
          <Typography variant="h6">Business Impact</Typography>
        </SkeletonWrapper>
        <SkeletonWrapper isLoading={globalLoader} variant="circular" width={21}>
          <Tooltip
            title={BUSINESS_IMPACT_TOOLTIP}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 353,
                },
              },
            }}
          >
            <InfoOutlined
              sx={{ color: "icon.mediumEmphasis", fontSize: "16px" }}
            />
          </Tooltip>
        </SkeletonWrapper>
      </Box>
      <ContentRow data-testid="business-impact">
        {/* Chart and Metrics */}
        <Box display="flex" gap={2} width="100%">
          <ChartWrapper>
            {globalLoader ? (
              <Box
                height="auto"
                width="100%"
                position="relative"
                sx={{ aspectRatio: 1 }}
              >
                <SkeletonWrapper
                  variant="circular"
                  width="100%"
                  height="100%"
                  isLoading={globalLoader}
                />
                <Box
                  position="absolute"
                  top="25%"
                  left="25%"
                  borderRadius={theme.shape.radius.full}
                  bgcolor="white"
                  width="50%"
                  height="50%"
                />
              </Box>
            ) : (
              <ResponsivePie
                colors={[
                  theme.palette.chart.dark.primary,
                  theme.palette.chart.light.primary,
                  theme.palette.chart.medium.primary,
                ]}
                data={[
                  { id: "cost", value: cost / sumAllValue },
                  { id: "cost_revenue", value: costRevenue / sumAllValue },
                  { id: "revenue", value: revenue / sumAllValue },
                ]}
                animate
                isInteractive={false}
                startAngle={90}
                endAngle={450}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                innerRadius={0.5}
              />
            )}
          </ChartWrapper>

          {/* Use the ImpactMetric component for each metric */}
          <Stack gap={2} flexGrow={0.5} alignSelf="center">
            <ImpactMetrics
              label="Cost"
              value={cost}
              color={theme.palette.chart.dark.primary}
              globalLoader={globalLoader}
            />
            <ImpactMetrics
              label="Revenue"
              value={revenue}
              color={theme.palette.chart.medium.primary}
              globalLoader={globalLoader}
            />
            <ImpactMetrics
              label="Cost & Revenue"
              value={costRevenue}
              color={theme.palette.chart.light.primary}
              globalLoader={globalLoader}
            />
          </Stack>
        </Box>
        <Stack gap={2}>
          <MetricsNumberCard
            label="Total"
            value={roundNumberToMagnitude(sumAllValue)}
            tooltip={`€ ${thousandSeparator(sumAllValue)}`}
            suffix={numberMagnitude(sumAllValue)}
            prefix="€"
            skeletonWidth={200}
          />
          <MetricsNumberCard
            label="Median"
            tooltip={`€ ${thousandSeparator(props.median ? props.median : 0)}`}
            value={roundNumberToMagnitude(props.median ? props.median : 0)}
            suffix={numberMagnitude(props.median)}
            prefix="€"
            color={theme.palette.text.mediumEmphasis}
            skeletonWidth={200}
          />
        </Stack>
      </ContentRow>
    </WidgetWrapper>
  );
}

export default BusinessImpact;
