import { ReactElement, useContext } from "react";
import { Typography, Box } from "@mui/material";
import theme from "../../../theme";
import { StartupCountByAssessment } from "../../../Types/Analytics";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import MetricsNumberCard from "./MetricsNumberCard";
import WidgetWrapper from "./WidgetWrapper";

interface StartupsProps {
  startupCount: StartupCountByAssessment;
}

function Startups(props: StartupsProps): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);

  return (
    <WidgetWrapper data-testid="startups-section">
      <SkeletonWrapper isLoading={globalLoader}>
        <Typography variant="h6">Startups</Typography>
      </SkeletonWrapper>
      <Box display="flex" flexDirection={{ md: "column" }} gap={2}>
        <MetricsNumberCard
          label="Curated"
          value={props.startupCount.curated}
          suffix="startups"
          color={theme.palette.chart.dark.primary}
        />
        <MetricsNumberCard
          label="Assessed"
          value={props.startupCount.qualified}
          suffix="startups"
        />
      </Box>
    </WidgetWrapper>
  );
}

export default Startups;
