import {
  Autocomplete,
  CircularProgress,
  IconButton,
  ListItem,
  TextField,
  Tooltip,
  debounce,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ReactElement, SyntheticEvent, useState } from "react";
import { Startup } from "../../../Types/Startup";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import CreateStartupModal from "../../Startup/CreateStartupModal";
import PitchbookSearchModal from "../Modals/PitchbookSearchModal/PitchbookSearchModal";
import useRoles from "../../../Hooks/useRoles";
import AddStartupExternalModal from "../../Startup/AddStartupExternalModal";

interface StartupSelectProps {
  assignedStartupIds: number[] | undefined;
  handleSelectStartup: (selectedStartup: Startup | null) => void;
  selectedStartup: Startup | null | undefined;
  label?: string;
}

const StyledTextField = styled(TextField)((props) => ({
  "& div.MuiOutlinedInput-root": {
    padding: props.label ? "8px" : "0px 8px 0px 0px",
  },
}));

const SimilarResultsLabel = styled(ListItem)(({ theme }) => ({
  "&.MuiAutocomplete-option[aria-disabled='true']": {
    fontStyle: "italic",
    fontSize: theme.typography.caption.fontSize,
    opacity: 1,
    color: theme.palette.grey[700],
  },
}));

const StartupSelect = (props: StartupSelectProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [startupOptions, setStartupOptions] = useState<Startup[]>([]);
  const [pitchbookModalOpen, setPitchbookModalOpen] = useState(false);
  const [createStartupModalOpen, setCreateStartupModalOpen] = useState(false);
  const [addStartupExternalModalOpen, setAddStartupExternalModalOpen] =
    useState(false);
  const [startupInput, setStartupInput] = useState("");
  const { isExternalUser } = useRoles();

  const searchForStartups = async (
    event: SyntheticEvent<Element, Event> | null,
    searchValue: string
  ): Promise<void> => {
    if (event?.type === "click") return;

    const trimmedSearchValue = searchValue.trim();
    // avoid fetching when a new startup is created
    if (event === null) return;

    if (trimmedSearchValue.length > 0) {
      setIsLoading(true);
      const startups = await StartupHttpService.getStartupsByName(
        trimmedSearchValue,
        true
      );
      let filteredStartups = startups.filter(
        (startup) => !props.assignedStartupIds?.includes(startup.id)
      );

      const filteredSimilarStartupNames = filteredStartups.filter((startup) =>
        startup.name.toLowerCase().includes(trimmedSearchValue.toLowerCase())
      );

      if (filteredSimilarStartupNames.length) {
        filteredStartups = filteredSimilarStartupNames;
        setStartupOptions([...filteredStartups]);
      } else {
        setStartupOptions([
          { id: -1, name: "Similar Results" } as Startup,
          ...filteredStartups,
        ]);
      }

      setIsLoading(false);
    } else {
      // when clear the field, there should be no startups
      setStartupOptions([]);
    }
  };

  const debouncedSearchForStartups = debounce(searchForStartups, 500);

  const handleChange = (selectedStartup: Startup | null) => {
    if (selectedStartup?.id === -1) {
      setPitchbookModalOpen(true);
    } else {
      props.handleSelectStartup(selectedStartup);
    }
  };

  const handleStartupImportAndCreate = async (id: number) => {
    setCreateStartupModalOpen(false);
    setPitchbookModalOpen(false);

    const startup = await StartupHttpService.getStartupById(id, true);
    props.handleSelectStartup(startup);
  };

  const handleAddStartupClick = () => {
    if (isExternalUser) {
      setAddStartupExternalModalOpen(true);
    } else {
      setPitchbookModalOpen(true);
    }
  };
  return (
    <>
      <Autocomplete
        id="startup-autocomplete"
        sx={{ width: "100%" }}
        forcePopupIcon={false}
        onInputChange={(event, newValue) =>
          debouncedSearchForStartups(event, newValue)
        }
        onChange={(_, selectedStartup) => {
          handleChange(selectedStartup);
        }}
        getOptionDisabled={(startup) => startup.id === -1}
        getOptionLabel={(startup) => startup.name}
        options={startupOptions}
        value={props.selectedStartup || null}
        noOptionsText="No Startup Found"
        loading={isLoading}
        // assign startup's id as a key, otherwise startup's name is being assigned as a key which react complains about.
        renderOption={(props, startup) => {
          if (startup.id === -1) {
            return (
              <SimilarResultsLabel {...props} key={startup.id}>
                {startup.name}
              </SimilarResultsLabel>
            );
          } else {
            return (
              <li {...props} key={startup.id}>
                {startup.name}
              </li>
            );
          }
        }}
        // deactivate filterOptions prop in order to making "Add" option to work
        filterOptions={(startups) => startups}
        renderInput={(params) => {
          return (
            <StyledTextField
              {...params}
              label={props.label}
              placeholder="Type startup name"
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <>
                        {params.InputProps.endAdornment}
                        <Tooltip
                          title="Create New Startup"
                          placement="right-end"
                        >
                          <IconButton
                            id="addNewStartup"
                            data-testid="add-new-startup"
                            onClick={handleAddStartupClick}
                          >
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setStartupInput(e.target.value)}
            />
          );
        }}
      />

      {pitchbookModalOpen && (
        <PitchbookSearchModal
          startupName={startupInput}
          pitchbookModalOpen={pitchbookModalOpen}
          handleModalClose={() => setPitchbookModalOpen(false)}
          handleImport={handleStartupImportAndCreate}
          handleCreateStartupModalOpen={() => setCreateStartupModalOpen(true)}
        />
      )}

      {createStartupModalOpen && (
        <CreateStartupModal
          startupName={startupInput}
          modalOpen={createStartupModalOpen}
          handleModalClose={() => setCreateStartupModalOpen(false)}
          handleCreateResponse={handleStartupImportAndCreate}
        />
      )}

      {addStartupExternalModalOpen && (
        <AddStartupExternalModal
          startupName={startupInput}
          modalOpen={addStartupExternalModalOpen}
          handleModalClose={() => setAddStartupExternalModalOpen(false)}
          handleCreateResponse={handleStartupImportAndCreate}
        />
      )}
    </>
  );
};

export default StartupSelect;
