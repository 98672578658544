import { Stack, styled } from "@mui/material";
import theme from "../../../theme";

const WidgetWrapper = styled(Stack)(() => ({
  flex: 3,
  height: "100%",
  backgroundColor: theme.palette.surface.primary.main,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

export default WidgetWrapper;
