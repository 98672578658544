import { Box, Button, Chip, IconButton, styled } from "@mui/material";
import {
  Close as CloseIcon,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useRef, useState, useEffect, ReactElement } from "react";

interface FilterChipsDisplayProps {
  filters: any;
  setFilters: (filters: any) => void;
  resetFilters: () => void;
  defaultFilters: any;
  sticky?: boolean;
}

const CHIPS_CONTAINER_INITIAL_HEIGHT = 40;

const FilterDisplay = styled(Box, {
  shouldForwardProp: (prop: PropertyKey) =>
    !["sticky", "isScrolled"].includes(prop as string),
})<{ sticky?: boolean; isScrolled?: boolean }>(
  ({ sticky, isScrolled, theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(sticky && {
      position: "sticky",
      top: theme.spacing(23),
      zIndex: 2,
      backgroundColor: theme.palette.background.default,
      width: `calc(100vw - ${theme.spacing(9)})`,
      marginLeft: `calc((100vw - 100% - ${theme.spacing(9)})/-2)`,
      boxShadow: isScrolled ? theme.boxShadows[3] : "none",
      borderBottom: isScrolled ? `1px solid ${theme.palette.divider}` : "none",
    }),
  })
);

const FilterWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  width: "1600px",
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(0.5, 3, 0.5, 3),
  gap: theme.spacing(4),
}));

const FilterChipsWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $showAll: boolean }>(({ theme, $showAll }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  height: $showAll ? "auto" : CHIPS_CONTAINER_INITIAL_HEIGHT,
  overflow: "hidden",
  padding: theme.spacing(0.25),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  "& .MuiChip-deleteIcon": {
    color: theme.palette.text.primary,
  },
  "& .MuiChip-deleteIcon:hover": {
    color: theme.palette.grey[500],
  },
  "&:focus-visible": {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: "-2px",
    zIndex: 1,
  },
  margin: theme.spacing(0.25),
}));

export default function FilterChipsDisplay({
  filters,
  setFilters,
  resetFilters,
  defaultFilters,
  sticky,
}: FilterChipsDisplayProps): ReactElement {
  const [viewMore, setViewMore] = useState(false);
  const [hiddenChipsCount, setHiddenChipsCount] = useState(0);
  const [viewMoreVisibility, setViewMoreVisibility] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const filterChipsRef = useRef<HTMLDivElement>(null);

  const SCROLL_THRESHOLD = 320;

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > SCROLL_THRESHOLD);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDeleteFilterChip = (filterKey: string, itemToDelete?: string) => {
    const updatedFilters = { ...filters };

    if (Array.isArray(updatedFilters[filterKey])) {
      updatedFilters[filterKey] = updatedFilters[filterKey].filter(
        (item: any) => item !== itemToDelete
      );
      if (updatedFilters[filterKey].length === 0 && defaultFilters[filterKey]) {
        updatedFilters[filterKey] = defaultFilters[filterKey];
      }
    } else if (typeof updatedFilters[filterKey] === "boolean") {
      updatedFilters[filterKey] = !updatedFilters[filterKey];
    } else {
      delete updatedFilters[filterKey];
    }

    setFilters(updatedFilters);
    localStorage.setItem("startupFilters", JSON.stringify(updatedFilters));
  };

  useEffect(() => {
    const updateScrollability = () => {
      const chipsContainer = filterChipsRef.current;

      if (chipsContainer) {
        const isSingleRow =
          chipsContainer.scrollHeight <= CHIPS_CONTAINER_INITIAL_HEIGHT;
        setViewMoreVisibility(!isSingleRow);

        const chipElements = Array.from(chipsContainer.children);
        const containerWidth = chipsContainer.clientWidth;
        let rowWidth = 0;
        let visibleChipsCount = 0;

        for (const chip of chipElements) {
          const chipWidth = chip.clientWidth + 8;
          if (rowWidth + chipWidth > containerWidth) {
            rowWidth = 0;
            if (
              visibleChipsCount * chip.clientHeight >
              CHIPS_CONTAINER_INITIAL_HEIGHT
            )
              break;
          }
          rowWidth += chipWidth;
          visibleChipsCount++;
        }
        setHiddenChipsCount(chipElements.length - visibleChipsCount);
      }
    };

    updateScrollability();
    window.addEventListener("resize", updateScrollability);

    return () => {
      window.removeEventListener("resize", updateScrollability);
    };
  }, [filters]);

  const toggleViewMore = () => setViewMore((prev) => !prev);

  return (
    <FilterDisplay sticky={sticky} isScrolled={isScrolled}>
      <FilterWrapper>
        <FilterChipsWrapper $showAll={viewMore} ref={filterChipsRef}>
          <Button startIcon={<CloseIcon />} onClick={resetFilters}>
            Clear
          </Button>
          {Object.entries(filters).map(([key, value]) => {
            if (key === "ventureClientReadiness") return null;

            const chips = Array.isArray(value)
              ? value.map((item, index) => (
                  <StyledChip
                    color="secondary"
                    key={`${key}-${index}`}
                    label={item}
                    onDelete={() => handleDeleteFilterChip(key, item)}
                  />
                ))
              : typeof value === "boolean" && value
              ? [
                  <StyledChip
                    color="secondary"
                    key={key}
                    label={
                      key === "receivedFunding"
                        ? "Received Funding"
                        : "Incomplete Profiles"
                    }
                    onDelete={() => handleDeleteFilterChip(key)}
                  />,
                ]
              : null;

            return chips;
          })}
          {filters.ventureClientReadiness.length < 3 &&
            filters.ventureClientReadiness.map(
              (state: string | undefined, index: any) => (
                <StyledChip
                  key={`ventureClientReadiness-${index}`}
                  color="secondary"
                  label={state}
                  onDelete={() =>
                    handleDeleteFilterChip("ventureClientReadiness", state)
                  }
                />
              )
            )}
        </FilterChipsWrapper>
        {!viewMore && hiddenChipsCount > 0 && (
          <Chip
            variant="counter"
            color="secondary"
            label={`+${hiddenChipsCount}`}
          />
        )}
        {viewMoreVisibility && (
          <IconButton
            sx={{ alignSelf: "start" }}
            color="secondary"
            onClick={toggleViewMore}
          >
            {viewMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        )}
      </FilterWrapper>
    </FilterDisplay>
  );
}
