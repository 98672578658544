import {
  Box,
  Grid,
  Slider,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import theme from "../../../theme";
import { ReactElement, useContext } from "react";
import { SPEED_TOOLTIP } from "../../../Constants/TooltipText";
import { InfoOutlined } from "@mui/icons-material";
import { ProjectSpeed } from "../../../Types/Analytics";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";

const Wrapper = styled(Stack)(() => ({
  flex: 3,
  height: "100%",
  backgroundColor: theme.palette.surface.primary.main,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.radius.minimal,
}));
const Header = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));
const SpeedItemCard = styled(Stack)(() => ({
  height: "100%",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.surface.secondary.main,
  gap: theme.spacing(2),
  borderRadius: theme.shape.radius.cardLarge,
}));

interface SpeedProps {
  projectSpeed: ProjectSpeed;
}

function Speed({ projectSpeed }: SpeedProps): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);
  return (
    <Wrapper data-testid="speed">
      <SkeletonWrapper isLoading={globalLoader}>
        <Header>
          <Typography variant="h6">Speed</Typography>
          <Tooltip
            title={SPEED_TOOLTIP}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 364,
                },
              },
            }}
          >
            <InfoOutlined
              sx={{ color: "icon.mediumEmphasis", fontSize: "16px" }}
            />
          </Tooltip>
        </Header>
      </SkeletonWrapper>
      <Grid container spacing={2}>
        <Grid item sm={6} md={12} lg={6}>
          <SkeletonWrapper isLoading={globalLoader} width="100%">
            <SpeedItemCard>
              <Typography variant="caption" color="text.mediumEmphasis">
                Discover to Pilot
              </Typography>
              <Stack gap={1}>
                <Slider
                  disabled
                  defaultValue={50}
                  aria-label="Disabled slider"
                  sx={{
                    "& .MuiSlider-thumb": {
                      display: projectSpeed.medianDiscoverToPilotDuration
                        ? "flex"
                        : "none",
                    },
                  }}
                />
                {projectSpeed.medianDiscoverToPilotDuration || globalLoader ? (
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr auto 1fr"
                    alignItems="center"
                  >
                    <Typography variant="overline" color="text.mediumEmphasis">
                      {projectSpeed.minimumDiscoverToPilotDuration || "--"}
                      <span style={{ color: theme.palette.text.disabled }}>
                        {" "}
                        d
                      </span>
                    </Typography>
                    <Box display="flex" gap={0.25}>
                      <Typography variant="h6" color="chart.medium.primary">
                        {projectSpeed.medianDiscoverToPilotDuration || "--"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="chart.medium.primary"
                        alignSelf="end"
                      >
                        days
                      </Typography>
                    </Box>
                    <Typography
                      variant="overline"
                      color="text.mediumEmphasis"
                      justifySelf="right"
                    >
                      {projectSpeed.maximumDiscoverToPilotDuration || "--"}
                      <span style={{ color: theme.palette.text.disabled }}>
                        {" "}
                        d
                      </span>
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="overline" color="text.mediumEmphasis">
                    No data to display due to insufficient data in the filtered
                    projects. <br /> Adjust filters or review project data.
                  </Typography>
                )}
              </Stack>
            </SpeedItemCard>
          </SkeletonWrapper>
        </Grid>
        <Grid item sm={6} md={12} lg={6}>
          <SkeletonWrapper isLoading={globalLoader} width="100%">
            <SpeedItemCard>
              <Typography variant="caption" color="text.mediumEmphasis">
                Pilot to Adoption
              </Typography>
              <Stack gap={1}>
                <Slider
                  disabled
                  defaultValue={50}
                  aria-label="Disabled slider"
                  sx={{
                    "& .MuiSlider-thumb": {
                      display: projectSpeed.medianPilotToAdoptionDuration
                        ? "flex"
                        : "none",
                    },
                  }}
                />
                {projectSpeed.medianPilotToAdoptionDuration || globalLoader ? (
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr auto 1fr"
                    alignItems="center"
                  >
                    <Typography variant="overline" color="text.mediumEmphasis">
                      {projectSpeed.minimumPilotToAdoptionDuration || "--"}
                      <span style={{ color: theme.palette.text.disabled }}>
                        {" d"}
                      </span>
                    </Typography>
                    <Box display="flex" gap={0.25}>
                      <Typography variant="h6" color="chart.medium.primary">
                        {projectSpeed.medianPilotToAdoptionDuration || "--"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="chart.medium.primary"
                        alignSelf="end"
                      >
                        days
                      </Typography>
                    </Box>
                    <Typography
                      variant="overline"
                      color="text.mediumEmphasis"
                      justifySelf="right"
                    >
                      {projectSpeed.maximumPilotToAdoptionDuration || "--"}
                      <span style={{ color: theme.palette.text.disabled }}>
                        {" "}
                        d
                      </span>
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="overline" color="text.mediumEmphasis">
                    No data to display due to insufficient data in the filtered
                    projects. <br /> Adjust filters or review project data.
                  </Typography>
                )}
              </Stack>
            </SpeedItemCard>
          </SkeletonWrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Speed;
