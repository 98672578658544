import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import theme from "../../../theme";
import { Project } from "../../../Types/Project";
import { UserContext } from "../../../Context/UserContext";
import { ProjectHttpService } from "../../../Http/Project/Project.http.service";
import UserSelectInput from "../../UI/InputFields/UserSelect";
import { User } from "../../../Types/User";
import { stageScores } from "../../../Constants/FunnelStages";
import { CreateOpportunityDTO, Opportunity } from "../../../Types/Opportunity";
import OpportunityHttpService from "../../../Http/Opportunity/Opportunity.Http.service";
import { useSnackbar } from "notistack";
import { LeadOpportunity, LeadProject } from "../../../Types/LeadProject";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import usePitchbookSync from "../../../Hooks/usePitchbookSync";
import Add from "@mui/icons-material/Add";
import CreateLeadProjectModal from "../../ProjectKanban/CreateLeadProjectModal";
import ScrollableDialogContent from "../../UI/Modals/ScrollableDialogContent";
import { capitalizeFirstLetter } from "../../../utils";
import useRoles from "../../../Hooks/useRoles";

interface AddStartupToProjectModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  startupId: number;
  startupName: string;
  handleSave: () => void;
}

const isProject = (project: Project | LeadProject): project is Project =>
  "funnelStage" in project;

const AddStartupToProjectModal = (
  props: AddStartupToProjectModalProps
): ReactElement => {
  const user = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { syncStartup } = usePitchbookSync();
  const { isExternalUser } = useRoles();
  const [projects, setProjects] = useState<(Project | LeadProject)[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<
    (Project | LeadProject)[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [projectsToDisplay, setProjectsToDisplay] =
    useState<(Project | LeadProject)[]>(projects);

  const [createLeadModalOpen, setCreateLeadModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [filterCriteria, setFilterCriteria] = useState<{
    userProjects: boolean;
    projectOwners: number[];
  }>({
    userProjects: user ? true : false,
    projectOwners: [],
  });

  useEffect(() => {
    let ignoreRequest = false;

    async function fetchProjectsAndLeads(filterCriteria: {
      userProjects: boolean;
      projectOwners: number[];
    }) {
      setIsLoading(true);
      let returnedProjects: Project[] = [];
      let returnedLeads: LeadProject[] = [];

      await Promise.all([
        ProjectHttpService.getProjects(filterCriteria).then((projects) => {
          returnedProjects = projects;
        }),
        LeadProjectsHttpService.getLeadProjects(filterCriteria).then(
          (leadProjects) => {
            returnedLeads = leadProjects;
          }
        ),
      ]);

      if (ignoreRequest) return;

      const groupedProjects = [...returnedProjects, ...returnedLeads];
      const groupedEarlyStageProjects = [
        ...returnedProjects.filter(
          (project) => stageScores[project.funnelStage] <= stageScores.assess
        ),
        ...returnedLeads,
      ];
      const sortedSearchResult =
        searchValue === ""
          ? groupedEarlyStageProjects
              .filter((project) => project.status !== "archived")
              .sort(sortProjectsByRelevance)
          : groupedProjects
              .filter((project) =>
                project.name.toLowerCase().includes(searchValue.toLowerCase())
              )
              .sort(sortProjectsByRelevance);

      const sortedProjects = groupedProjects.sort(sortProjectsByRelevance);

      setProjects(sortedProjects);
      setProjectsToDisplay(sortedSearchResult);
      setIsLoading(false);
    }

    fetchProjectsAndLeads(filterCriteria);

    return () => {
      ignoreRequest = true;
    };
  }, [filterCriteria, refresh]);

  const handleMyProjectsSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCriteria({ userProjects: e.target.checked, projectOwners: [] });
    setSelectedProjects([]);
  };

  const handleProjectOwnerSelect = (userSelected: User | undefined) => {
    setFilterCriteria({
      ...filterCriteria,
      ...(userSelected && {
        projectOwners: [userSelected.id],
      }),
    });
  };

  const handleProjectSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);

    const searchResult =
      newSearchValue === ""
        ? projects.filter((project) => {
            const isArchived = project.status === "archived";

            if (isArchived) return false;
            else if (!isProject(project)) return true;
            else return stageScores[project.funnelStage] <= stageScores.assess;
          })
        : projects.filter((project) =>
            project.name.toLowerCase().includes(newSearchValue.toLowerCase())
          );

    setProjectsToDisplay(searchResult);
  };

  const handleAddToProject = async () => {
    if (selectedProjects.length === 0) return;

    await syncStartup(props.startupId);

    for (const project of selectedProjects) {
      if (isProject(project)) {
        const createdOpportunity: CreateOpportunityDTO = {
          projectId: project.id,
          startupId: props.startupId,
          isQualified: project.funnelStage === "discover" ? false : true,
        };

        try {
          await OpportunityHttpService.createOpportunity(createdOpportunity);
          enqueueSnackbar(
            `${props.startupName} has been successfully added to ${project.name}`,
            {
              variant: "success",
              action: () => (
                <Button href={`/projects/${project.id}`}>Go To Project</Button>
              ),
            }
          );
        } catch (error) {
          enqueueSnackbar("Something went wrong while adding to the project", {
            variant: "error",
          });
        }
      } else {
        try {
          await LeadProjectsHttpService.addLeadOpportunity({
            leadProjectId: project.id,
            startupId: props.startupId,
          });
          enqueueSnackbar(
            `${props.startupName} has been successfully added to ${project.name}`,
            {
              variant: "success",
              action: () => (
                <Button href={`/lead-project/${project.id}`}>
                  Go To Project
                </Button>
              ),
            }
          );
        } catch (error) {
          enqueueSnackbar("Something went wrong while adding to the lead", {
            variant: "error",
          });
        }
      }
    }

    props.setModalOpen(false);
    props.handleSave();
  };

  const handleCheckProject = (project: Project | LeadProject) => {
    const index = selectedProjects.findIndex((selectedProject) => {
      return isProject(selectedProject) === isProject(project)
        ? selectedProject.id === project.id
        : false;
    });

    if (index === -1) {
      setSelectedProjects((prev) => [...prev, project]);
    } else {
      const newSelectedProjects = selectedProjects;
      newSelectedProjects.splice(index, 1);
      setSelectedProjects([...newSelectedProjects]);
    }
  };

  const handleModalClose = async (createdLeadId?: number) => {
    if (createdLeadId) {
      setRefresh((prev) => !prev);
      const createdLead = await LeadProjectsHttpService.getLeadProjectById(
        createdLeadId
      );
      setSelectedProjects((selectedProjects) => [
        ...selectedProjects,
        createdLead,
      ]);
    }
    setCreateLeadModalOpen(false);
  };

  const sortProjectsByRelevance = (
    project1: Project | LeadProject,
    project2: Project | LeadProject
  ): number => {
    const stage1 = isProject(project1) ? stageScores[project1.funnelStage] : 0;
    const stage2 = isProject(project2) ? stageScores[project2.funnelStage] : 0;
    const lastModifiedDate1 = project1.lastModifiedDate ?? project1.dateCreated;
    const lastModifiedDate2 = project2.lastModifiedDate ?? project2.dateCreated;

    if (stage1 && stage2) {
      if (stage1 > 2 && stage2 <= 2) return 1;
      else if (stage1 <= 2 && stage2 > 2) return -1;
    }

    return lastModifiedDate1 > lastModifiedDate2 ? -1 : 1;
  };

  return (
    <>
      <Dialog
        id="add-startup-to-project-modal"
        fullWidth
        open={props.modalOpen}
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>Add Startup to Lead or Project</DialogTitle>
        <TextField
          data-testid="search-project-input"
          label="Search Lead or Project"
          value={searchValue}
          onChange={handleProjectSearch}
          InputLabelProps={{ shrink: true }}
        />
        <FormControlLabel
          data-testid="my-projects-switch"
          control={
            <Switch
              onChange={handleMyProjectsSwitch}
              checked={filterCriteria.userProjects}
            />
          }
          label={
            <Typography variant="subtitle2" ml={1}>
              My Leads & Projects
            </Typography>
          }
        />
        {!filterCriteria.userProjects && (
          <UserSelectInput
            label="Project Owner"
            defaultUserId={-1}
            onChange={handleProjectOwnerSelect}
          />
        )}
        <Stack gap={2} maxHeight="calc(100vh - 414px)">
          {filterCriteria.userProjects && searchValue === "" && (
            <Typography>
              Or add to one of the recently modified early stage projects
            </Typography>
          )}
          {(filterCriteria.userProjects ||
            !!filterCriteria.projectOwners?.length) && (
            <ScrollableDialogContent sx={{ gap: 2 }}>
              <Stack gap={1}>
                {!isLoading
                  ? projectsToDisplay.map((project, index) => {
                      const isStartupInProject = !!(
                        project.opportunities as Array<
                          Opportunity | LeadOpportunity
                        >
                      )?.find((opp) => opp.startupId === props.startupId);

                      const isSelected = !!selectedProjects.find(
                        (selectedProject) => {
                          return isProject(selectedProject) ===
                            isProject(project)
                            ? selectedProject.id === project.id
                            : false;
                        }
                      );

                      return (
                        <Box
                          key={index}
                          display="flex"
                          gap={theme.spacing(2)}
                          padding={2}
                          bgcolor="surface.secondary.main"
                        >
                          <Checkbox
                            data-testid={`checkbox-project-${index}`}
                            checked={isSelected || isStartupInProject}
                            onChange={() => handleCheckProject(project)}
                            disabled={isStartupInProject}
                          />
                          <Box overflow="hidden">
                            <Typography
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              variant="subtitle2"
                            >
                              {project.name}
                            </Typography>
                            <Box display="flex" gap={2}>
                              {isProject(project) ? (
                                <>
                                  {!isExternalUser && (
                                    <Typography>
                                      {project.businessUnit.ventureClient.name}
                                    </Typography>
                                  )}

                                  <Typography>
                                    {capitalizeFirstLetter(project.funnelStage)}
                                  </Typography>
                                  <Typography>
                                    {project.businessUnit.name}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  {!isExternalUser && (
                                    <Typography>
                                      {project.ventureClient.name}
                                    </Typography>
                                  )}

                                  <Typography>Lead</Typography>
                                  <Typography>
                                    {`${project.opportunities?.length ?? "0"}
                                  Startup(s)`}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  : [...Array(5)].map((_, index) => (
                      <Skeleton key={index} height={72} />
                    ))}
              </Stack>
            </ScrollableDialogContent>
          )}
          <Button
            sx={{ maxWidth: "fit-content" }}
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            onClick={() => {
              setCreateLeadModalOpen(true);
            }}
          >
            Create New Lead
          </Button>
        </Stack>
        <DialogActions sx={{ marginTop: 0 }}>
          <Button
            data-testid="close-modal-button"
            onClick={() => props.setModalOpen(false)}
          >
            Close
          </Button>
          <Button
            data-testid="add-project-button"
            disabled={selectedProjects.length === 0}
            variant="contained"
            onClick={handleAddToProject}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {createLeadModalOpen && (
        <CreateLeadProjectModal
          modalOpen={createLeadModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default AddStartupToProjectModal;
