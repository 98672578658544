import { Box, Paper, Stack, Tab, Tabs, debounce } from "@mui/material";
import {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import theme from "../../theme";
import { ProjectHttpService } from "../../Http/Project/Project.http.service";
import {
  GenericProject,
  ProjectStartupOpportunityAssociation,
} from "../../Types/Project";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { useSnackbar } from "notistack";
import ProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import Metrics from "./Metrics/Metrics";
import ProjectsDataGrid from "./ProjectsDataGrid";
import {
  useGridApiRef,
  GridInitialState,
  gridFilteredSortedRowIdsSelector,
  GridRowId,
} from "@mui/x-data-grid-premium";
import SearchBar from "../UI/SearchBar";
import ProjectsDataGridToolbar from "./ProjectsDataGridToolbar/ProjectsDataGridToolbar";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import { LeadProject } from "../../Types/LeadProject";
import LeadProjectsDataGrid from "./LeadProjectsDataGrid";
import PageHeader from "../UI/PageHeader";
import LeadProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/LeadProjectDetailsModal";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import LeadsMetrics from "./Metrics/LeadsMetrics";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

function ProjectList(): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader, globalLoader } = useContext(GlobalLoaderContext);
  const apiRef = useGridApiRef();

  const [projects, setProjects] = useState<GenericProject[]>([]);
  const [filteredIds, setFilteredIds] = useState<GridRowId[]>([]);
  const [leadProjects, setLeadProjects] = useState<LeadProject[]>([]);
  const [selectedProject, setSelectedProject] =
    useState<ProjectStartupOpportunityAssociation>();
  const [selectedLeadProject, setSelectedLeadProject] = useState<LeadProject>();
  const [modalOpen, setModalOpen] = useState(false);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [gridState, setGridState] = useState<GridInitialState | null>(null);
  const [leadsGridState, setLeadsGridState] = useState<GridInitialState | null>(
    null
  );
  const [currentTab, setCurrentTab] = useState<"project" | "lead">("project");
  const previousFilteredIds = useRef<(string | number)[]>([]);
  useEffect(() => {
    if (currentTab === "project") {
      getGenericProjects();
    } else {
      getGenericLeads();
    }
  }, [debouncedSearchValue, currentTab]);

  useEffect(() => {
    document.title = `Data & Reports - Venture Client Platform`;
  }, []);

  const getGenericProjects = async () => {
    setGlobalLoader(true);
    try {
      const genericProjects = await ProjectHttpService.getAllGenericProjects(
        debouncedSearchValue.trim()
      );
      setProjects(genericProjects);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const getGenericLeads = async () => {
    setGlobalLoader(true);
    try {
      const genericLeadProjects =
        await LeadProjectsHttpService.getAllGenericLeadProjects(
          debouncedSearchValue.trim()
        );
      setLeadProjects(genericLeadProjects);
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleProjectModalOpen = (
    project: ProjectStartupOpportunityAssociation
  ) => {
    setSelectedProject(project);
    setSelectedLeadProject(undefined);
    setModalOpen(true);
  };

  const handleLeadModalOpen = (project: LeadProject) => {
    setSelectedLeadProject(project);
    setSelectedProject(undefined);
    setModalOpen(true);
  };

  const handleValueChange = (value: string) => {
    setSearchValue(value);
    debouncedStateChange(value);
  };

  const handleTabChange = (tab: "project" | "lead") => {
    setCurrentTab(tab);
    handleValueChange("");
  };

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      setDebouncedSearchValue(searchVal);
    }, 500),
    []
  );

  const syncState = (stateToSave: GridInitialState | null) => {
    if (currentTab === "project") {
      setGridState(stateToSave);
    } else {
      setLeadsGridState(stateToSave);
    }
  };

  const handleStateChange = () => {
    const filteredId = gridFilteredSortedRowIdsSelector(apiRef);

    if (
      JSON.stringify(previousFilteredIds.current) !== JSON.stringify(filteredId)
    ) {
      previousFilteredIds.current = filteredId;
      setFilteredIds(filteredId);
    }
  };

  return (
    <Fragment>
      <PageHeader
        title="These are all of your projects."
        subtitle="Easily search for and identify projects, and generate custom
              reports from your project data."
      />
      <ContentWrapper sx={{ paddingTop: 5 }}>
        <Stack gap={4}>
          <Tabs value={currentTab}>
            <Tab
              label="Projects"
              value="project"
              onClick={() => handleTabChange("project")}
            />
            <Tab
              label="Leads"
              value="lead"
              data-testid="lead-projects-tab"
              onClick={() => handleTabChange("lead")}
            />
          </Tabs>
          {currentTab === "project" && (
            <Metrics
              projects={projects.filter((project) =>
                filteredIds.includes(project.id)
              )}
            />
          )}
          {currentTab === "lead" && (
            <LeadsMetrics
              totalLeads={
                leadProjects.filter((project) =>
                  filteredIds.includes(project.id)
                ).length
              }
            />
          )}
          <Stack
            sx={{
              height: "calc(100vh - 234px)",
              background: "transparent",
              gap: theme.spacing(2),
            }}
            component={Paper}
            id="project-list"
          >
            <Box display="flex" justifyContent="space-between">
              <SearchBar
                key={"search-" + currentTab}
                searchValue={searchValue}
                handleValueChange={handleValueChange}
                entity={currentTab}
              />
              <ProjectsDataGridToolbar
                apiRef={apiRef}
                syncState={syncState}
                currentTab={currentTab}
              />
            </Box>
            {currentTab === "project" ? (
              <ProjectsDataGrid
                rows={projects}
                loading={globalLoader}
                apiRef={apiRef}
                onRowClick={(rowData) =>
                  handleProjectModalOpen(
                    rowData.row as ProjectStartupOpportunityAssociation
                  )
                }
                gridState={gridState}
                onStateChange={handleStateChange}
              />
            ) : (
              <LeadProjectsDataGrid
                rows={leadProjects}
                loading={globalLoader}
                apiRef={apiRef}
                onRowClick={(rowData) =>
                  handleLeadModalOpen(rowData.row as LeadProject)
                }
                gridState={leadsGridState}
                onStateChange={handleStateChange}
              />
            )}
            {modalOpen && selectedProject && (
              <ProjectDetailsModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                projectId={selectedProject.id}
                shouldOpenInNewTab
              />
            )}
            {modalOpen && selectedLeadProject && (
              <LeadProjectDetailsModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                leadProjectId={selectedLeadProject.id}
                shouldOpenInNewTab
              />
            )}
          </Stack>
        </Stack>
      </ContentWrapper>
    </Fragment>
  );
}

export default ProjectList;
