import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ProjectKanban from "./Components/ProjectKanban/ProjectKanban";
import SideNav from "./Components/Navigation/SideNav";
import ApplicationBar from "./Components/Navigation/ApplicationBar";
import { ReactElement } from "react";
import { Auth, Analytics } from "aws-amplify";
import { Amplify } from "@aws-amplify/core";
import ProjectDetailsPage from "./Components/ProjectDetails/ProjectDetailsPage";
import StartupList from "./Components/Startup/StartupList";
import StartupDetailsPage from "./Components/Startup/DetailsPage/StartupDetailsPage";
import VentureClientList from "./Components/VentureClient/VentureClientList";
import ContactList from "./Components/Contacts/ContactList";
import VentureClientDetailsPage from "./Components/VentureClient/DetailsPage/DetailsPage";
import Authentication from "./Components/Authentication/Authentication";
import { User } from "./Types/User";
import UserHttpService from "./Http/User/User.Http.service";
import { UserContext } from "./Context/UserContext";
import { getClientSSO, getUserRole } from "./utils";
import { GlobalLoaderContext } from "./Context/LoaderContext";
import ProjectList from "./Components/ProjectList/ProjectList";
import { ProjectRulesHttpService } from "./Http/ProjectRules/ProjectRules.http.service";
import { Box, styled } from "@mui/material";
import Footer from "./Components/UI/Footer";
import LeadProjectPage from "./Components/LeadProject/LeadProject";
import useSessionCheck from "./Hooks/useSessionCheck";
import SessionWarning from "./Components/UI/SessionWarning";

interface ExtendedAuthConfig {
  userPoolId: string | undefined;
  userPoolWebClientId: string | undefined;
  identityPoolId: string | undefined;
  region: string;
  cookieStorage: {
    sameSite: string;
    secure: boolean;
  };
  oauth?: {
    domain: string | undefined;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
}

const clientSSO = getClientSSO();

const baseConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: "eu-central-1",
    cookieStorage: {
      sameSite: "strict",
      secure: true,
    },
  } as ExtendedAuthConfig,
  Analytics: {
    disabled: process.env.REACT_APP_ENVIRONMENT === "production" ? false : true,
    AWSPinpoint: {
      appId: process.env.REACT_APP_AWS_PINPOINTID,
      region: "eu-central-1",
    },
  },
};

if (clientSSO) {
  baseConfig.Auth.oauth = {
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
    scope: ["email", "openid"],
    redirectSignIn: clientSSO.redirectSignIn,
    redirectSignOut: clientSSO.redirectSignOut,
    responseType: "code",
  };
}

Amplify.configure(baseConfig);

const Content = styled("main")(() => ({
  flexGrow: 1,
}));

export default function App(): ReactElement {
  const [globalLoader, setGlobalLoader] = useState<boolean>(false);
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [activeUser, setActiveUser] = useState<User>();

  const handleSignOut = async () => {
    await Auth.signOut();
    setSignedIn(false);
    setRemainingSessionTime(480);
  };

  const { remainingSessionTime, setRemainingSessionTime } = useSessionCheck({
    signedIn,
    handleSignOut,
  });

  const showWarning = remainingSessionTime <= 30;

  useEffect(() => {
    if (!signedIn || !activeUser) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          UserHttpService.getUser(-1).then((user) => {
            setActiveUser(user);
            setSignedIn(true);

            const endpoint = {
              Demographic: {
                Platform: "VCLOS",
              },
              userId: user.email,
              userAttributes: {
                ventureClients: user.roles.map(
                  (role) => role.ventureClient?.name || "Admin"
                ),
                role: [getUserRole(user)],
                name: [user.name],
              },
            };
            Analytics.updateEndpoint(endpoint);

            ProjectRulesHttpService.getProjectFieldRules().then((rules) => {
              window.localStorage.setItem(
                "projectFieldRules",
                JSON.stringify(rules)
              );
            });
            ProjectRulesHttpService.getProjectFileRules().then((rules) => {
              window.localStorage.setItem(
                "projectFileRules",
                JSON.stringify(rules)
              );
            });
          });
        })
        .catch(() => {
          setActiveUser(undefined);
          window.localStorage.clear();
          setSignedIn(false);
        });
    }
  }, [signedIn]);

  switch (signedIn && !!activeUser) {
    case false:
      return <Authentication setSignedIn={setSignedIn} />;
    case true:
      return (
        <UserContext.Provider value={activeUser}>
          <GlobalLoaderContext.Provider
            value={{ globalLoader, setGlobalLoader }}
          >
            <Router>
              <Box minHeight="100vh" position="relative" pb={7.5}>
                <Box display="flex">
                  <CssBaseline />
                  <ApplicationBar />
                  <SideNav handleSignOut={handleSignOut} />
                  <Content>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => {
                          return <Redirect to="/projects" />;
                        }}
                      />
                      <Route exact path="/projects" component={ProjectKanban} />
                      <Route exact path="/data" component={ProjectList} />
                      <Route
                        path="/lead-project/:id"
                        component={LeadProjectPage}
                      />
                      <Route
                        path="/projects/:id"
                        component={ProjectDetailsPage}
                      />
                      <Route exact path="/startups" component={StartupList} />
                      <Route
                        path="/startups/:id"
                        component={StartupDetailsPage}
                      />
                      <Route
                        exact
                        path="/venture-clients"
                        component={VentureClientList}
                      />
                      <Route path="/contacts" component={ContactList} />
                      <Route
                        path="/venture-clients/:id"
                        component={VentureClientDetailsPage}
                      />

                      {/* Fallback route for undefined paths*/}
                      <Route
                        render={() => {
                          return <Redirect to="/" />;
                        }}
                      />
                    </Switch>
                  </Content>
                </Box>
                <Footer />
                {showWarning && (
                  <SessionWarning
                    handleSignOut={handleSignOut}
                    remainingSessionTime={remainingSessionTime}
                  />
                )}
              </Box>
            </Router>
          </GlobalLoaderContext.Provider>
        </UserContext.Provider>
      );
  }
}
