import {
  Box,
  Button,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import GrainIcon from "@mui/icons-material/Grain";
import AssessmentStepper from "./AssessmentStepper";
import { ReactElement, useEffect, useState } from "react";
import { Opportunity } from "../../../../../Types/Opportunity";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import theme from "../../../../../theme";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../../utils";
import { AiHttpService } from "../../../../../Http/Ai/Ai.http.service";
import AutoGenerateBlurBackground from "../../../../UI/AutoGenerateBlurBackground";

const DEFAULT_TEXT =
  "To generate a summary, please provide the individual decision rationale for each startup and, if applicable, select the leading startup.";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...theme.typography.body1,
    minWidth: "356px",
  },
}));

interface Props {
  opportunities: Opportunity[];
  assessmentSummary: string | null;
  projectId: number;
  handleSave: () => void;
}

const AssessmentReport = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const [initialSummary, setInitialSummary] = useState(props.assessmentSummary);
  const [summary, setSummary] = useState(initialSummary);
  const [isGenerating, setIsGenerating] = useState(false);

  const selectedStartup = props.opportunities.find(
    (opportunity) => opportunity.isSelectedForPilot
  );
  const [initialStartup, setInitialStartup] = useState(selectedStartup);

  const qualifiedOpportunities = props.opportunities.filter(
    (opp) => (opp as Opportunity).isQualified
  );
  const haveAllDecisionRationale =
    qualifiedOpportunities.length > 0 &&
    qualifiedOpportunities.every(
      (opp) => (opp as Opportunity).assessmentDecision
    );

  const summaryToBeShown = haveAllDecisionRationale ? summary : DEFAULT_TEXT;
  const disableButton = !haveAllDecisionRationale || editMode || isGenerating;

  const handleCancel = () => {
    setEditMode(false);
    setSummary(initialSummary);
  };

  const saveSummary = async (value: string | null) => {
    setEditMode(false);

    await ProjectHttpService.updateProject({
      id: props.projectId,
      assessmentSummary: value,
    })
      .then(() => {
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not update assessment summary: ${errorMessage}`,
          { variant: "error" }
        );
      });
  };

  const generateSummary = async () => {
    setIsGenerating(true);
    await AiHttpService.generateAssessmentSummary(props.projectId)
      .then((summary) => {
        setSummary(summary);
        setInitialSummary(summary);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not generate assessment summary: ${errorMessage}`,
          { variant: "error" }
        );
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  useEffect(() => {
    if (!haveAllDecisionRationale && summary) {
      setInitialSummary(null);
      setSummary(null);
      saveSummary(null);
    }

    if (
      haveAllDecisionRationale &&
      (props.assessmentSummary === null ||
        selectedStartup?.id !== initialStartup?.id)
    ) {
      generateSummary();
      setInitialStartup(selectedStartup);
    }
  }, [haveAllDecisionRationale, selectedStartup]);

  return (
    <Stack gap={2} mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle1">Assessment Report</Typography>
        <Box display="flex" gap={1}>
          <StyledTooltip title="To generate a summary, please provide the individual decision rationale for each startup and, if applicable, select the leading startup.">
            <span>
              <Button
                variant="contained"
                color="secondary"
                disabled={disableButton}
                onClick={generateSummary}
              >
                <GrainIcon fontSize="small" sx={{ mr: 1 }} />
                Generate Summary
              </Button>
            </span>
          </StyledTooltip>

          <Button
            variant="contained"
            onClick={() => setEditMode(true)}
            disabled={disableButton}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 3fr">
        <AssessmentStepper opportunities={props.opportunities} />
        <Box
          position="relative"
          sx={{
            "& .MuiTypography-root.MuiTypography-body1": {
              color: haveAllDecisionRationale
                ? "inherit"
                : theme.palette.text.disabled,
            },
          }}
        >
          <CustomTextField
            fullWidth
            multiline
            label="Summary"
            editMode={editMode}
            minRows={9}
            value={summary}
            displayValue={summaryToBeShown}
            onChange={(e) => setSummary(e.target.value)}
            maxCharacter={800}
          />
          {isGenerating && <AutoGenerateBlurBackground />}
        </Box>
      </Box>
      {editMode && (
        <Box display="flex" gap={2} justifyContent="flex-end">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={() => saveSummary(summary)}>
            Save
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default AssessmentReport;
