import { ReactElement, useContext } from "react";
import { styled } from "@mui/styles";
import SkeletonWrapper from "../../UI/SkeletonWrapper";
import theme from "../../../theme";
import { Stack, Tooltip, Typography } from "@mui/material";
import { GlobalLoaderContext } from "../../../Context/LoaderContext";

const CardWrapper = styled(Stack)(() => ({
  backgroundColor: theme.palette.surface.secondary.main,
  padding: theme.spacing(2),
  height: "72px",
  minWidth: "0",
  width: "100%",
  borderRadius: theme.shape.radius.minimal,
}));

interface MetricsNumberCardProps {
  label: string;
  value: number | string;
  tooltip?: string;
  prefix?: string;
  suffix?: string;
  skeletonWidth?: number | string;
  color?: string;
}

function MetricsNumberCard(props: MetricsNumberCardProps): ReactElement {
  const {
    label,
    value,
    tooltip,
    prefix,
    suffix,
    skeletonWidth = "100%",
    color = theme.palette.chart.dark.secondary,
  } = props;
  const { globalLoader } = useContext(GlobalLoaderContext);

  return (
    <SkeletonWrapper isLoading={globalLoader} width={skeletonWidth}>
      <CardWrapper>
        <Typography variant="caption" color="text.mediumEmphasis">
          {label}
        </Typography>
        <Tooltip title={tooltip} placement="top">
          <Typography
            color={color}
            display="flex"
            alignItems="flex-end"
            gap={1}
          >
            {prefix && (
              <Typography variant="subtitle2" component="span">
                €
              </Typography>
            )}
            <Typography
              variant="h4"
              component="span"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
            {suffix && (
              <Typography variant="subtitle2" component="span">
                {suffix}
              </Typography>
            )}
          </Typography>
        </Tooltip>
      </CardWrapper>
    </SkeletonWrapper>
  );
}

export default MetricsNumberCard;
